.App {
  background-image: url('./images/sunil.png');
  background-size: contain;
  background-position: 50% 2%;
  background-repeat: no-repeat;
  text-align: center;
  padding-top: 10vh;
}

.page {
  padding-top: 15vh;
  height: auto;
  min-height: fit-content;
  width: 100%;
}

.content {
  width: 90%;
  max-width: 1000px;
  height: auto;
  min-height: fit-content;
  margin-top: 5vw;
  margin-bottom: 5vw;
  margin-left: auto;
  margin-right: auto;
}

.content p {
  text-align: left;
  width: fit-content;
  padding: 9px;
  padding-left: 11px;
  display: grid;
  border-left: .2em solid var(--main);
  user-select: all;
}

.content p span {
  font-size: 1.2em;
}

.content p span:hover {
  cursor: text;
}

.cards {
  margin-left: auto;
  margin-right: auto;
  height: fit-content;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  grid-gap: 20px;
  padding-bottom: 50px;
}

.card {
  background-color: var(--dark-semi-transparent);
  height: fit-content;
  min-height: fit-content;
  margin-left: auto;
  margin-right: auto;
  border-bottom: 4px solid var(--main);
}

.past .title {
  display: grid;
  text-align: center;
  font-size: 1.7em;
  color: var(--main);
}

.past .title a {
  text-decoration: none;
  font-size: 1.2em;
  color: var(--light);
  font-style: italic;
}

@media only screen and (min-width: 1350px) {

  .cards {
    max-width: 1000px;
  }

}

@media only screen and (min-width: 925px) {

  .App {
    background-attachment: fixed;
  }

}

@media only screen and (max-width: 925px) {

  .App {
    background-attachment: scroll;
  }

}

@media only screen and (max-width: 900px) {

  .cards {
    width: 100%;
  }

  .card {
    width: 100%;
    margin-bottom: 50px;
    height: fit-content;
  }

  .future {
    height: 0;
  }

  .past {
    height: fit-content;
    min-height: fit-content !important;
    width: 98%;
    margin: 0;
    padding: 1%;
    transition: all.2s;
  }

  .future .web-ctn {
    display: none;
  }

  .past .mobile-ctn {
    text-align: left;
    font-size: 1.2em;
    display: list-item;
    list-style-type: none;
  }

  .past .mobile-ctn br {
    margin-bottom: 20px;
  }

  .past .mobile-ctn a {
    float: right;
    text-decoration: none;
    font-style: italic;
    color: var(--main);
    line-height: 5vh;
    margin: 9px;
  }

  .past .mobile-ctn span {
    width: fit-content;
    margin: 9px;
    padding: 7px;
    border-radius: 2em;
    font-size: .7em;
    line-height: 5vh;
    white-space: nowrap;
    color: var(--main);
    background-color: rgba(140, 20, 252, 0.14);
    border: .1em solid var(--main);
  }

  .lang {
    display: none;
  }

}

@media only screen and (min-width: 856px) and (max-width: 925px) {
  .cards {
    margin-top: 100px !important;
  }
}

@media only screen and (min-width: 900px) {

  .cards {
    width: 95%;
  }

  .card {
    width: calc(50% - 20px);
    height: 550px;
    margin-bottom: 100px;
  }

  .card:hover {
    background-color: var(--dark-semi-transparent);
  }

  .future {
    position: relative;
    width: 100%;
    margin: 0;
    margin-top: 25px;
    font-size: 1.2em;
  }

  .past {
    height: fit-content;
    width: 98%;
    margin: 0;
    padding: 1%;
    transition: all.3s ease;
  }

  .card .web-ctn a {
    padding: 9px;
    border: 2px solid var(--light);
    width: calc(96% - 18px - .2em);
  }

  .past .mobile-ctn {
    display: none;
  }

  .future .web-ctn {
    user-select: none;
    margin: 1% auto !important;
    text-align: center;
    font-size: 1.2em;
    display: grid;
  }

  .future .web-ctn a {
    user-select: none;
    text-decoration: none;
    font-style: italic;
    width: 100%;
    border: 2px solid transparent;
    margin: 25px auto !important;
  }

  .future .web-ctn a:hover {
    text-decoration: underline;
  }

  .lang {
    opacity: 1;
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    font-size: .6em;
    width: 100%;
    margin-top: 75px;
    transition: opacity .2s ease-in 1s;
  }

  .lang span {
    width: fit-content;
    margin: 9px auto;
    padding: 7px;
    border-radius: 2em;
    color: var(--main);
    background-color: rgba(140, 20, 252, 0.14);
    border: .1em solid var(--main);
    white-space: nowrap;
  }

}