:root {
  --black: #161616;
  --grey: #777;
  --light: #f0f8ff;
  --main: #8c14fc;
  --dark-semi-transparent: rgba(0, 0, 0, 0.7);
}

::-moz-selection {
  background: var(--main);
  color: var(--light);
}

::selection {
  background: var(--main);
  color: var(--light);
}

body {
  margin: 0;
  padding: 0;
  font-family: 'Fira Mono', monospace;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  scroll-behavior: smooth;
  background-color: var(--black);
  overflow-x: hidden;
}

a,
p,
div,
h1,
h2,
button,
span,
input,
textarea,
select,
li,
tr,
td,
table,
code {
  font-family: 'Fira Mono', monospace;
  color: var(--light);
}

h1,
h2 {
  font-size: 2em;
  text-align: left;
}

h2 {
  color: var(--main);
}

.mt-mb-9 {
  padding-top: 9px !important;
  padding-bottom: 9px !important;
}

.no_display {
  display: none !important;
}