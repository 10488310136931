.page {
    position: relative;
    width: 100vw;
    text-align: center;
}

.social-btns {
    width: 95%;
    max-width: 1000px;
    margin-left: auto;
    margin-right: auto;
    height: auto;
    display: inline-flex;
    flex-wrap: nowrap;
}

.social-btns a {
    height: 17vw;
    width: calc(100% / 3);
    max-height: 100px;
    margin-top: 5%;
    line-height: min(22vw, 130px);
    margin-left: auto;
    margin-right: auto;
}

.social-btns a:first-child {
    border-radius: 25px 0 0 25px;
}

.social-btns a:last-child {
    border-radius: 0 25px 25px 0;
}

.social-btns a:hover {
    border-color: transparent;
    background-color: var(--main);
}

.social-btns a:hover .fa {
    color: var(--black);
}

.fa {
    color: var(--main);
    text-align: center;
}

@media only screen and (min-width:1000px) {

    .fa {
        font-size: min(9vw, 60px);
    }

    .contact p {
        font-size: 1.4em;
    }

}

@media only screen and (max-width:1000px) {

    .fa {
        font-size: min(10vw, 70px);
    }

    .contact p {
        font-size: 1.1em;
    }

}

.contact {
    margin-top: 100px;
    height: auto;
    width: 95%;
    max-width: 1000px;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 100px;
}

.form {
    margin: 2%;
    width: 96%;
    height: fit-content;
    display: grid;
    margin-left: auto;
    margin-right: auto;
    align-items: center;
}

.form p{
    position: relative;
}

input,
textarea {
    position: relative;
    background-color: transparent;
    border: .1em solid var(--main);
    color: var(--main);
    font-size: 1.4em;
    border-radius: 9px;
    width: calc(100% - 18px);
    height: auto;
    padding: 9px;
    padding-top: 14px;
    transition: all.2s;
}

input:active,
input:target,
input:focus,
textarea:active,
textarea:target,
textarea:focus {
    outline: none;
    opacity: 1;
}

textarea {
    resize: none;
    height: 200px !important;
    min-height: fit-content;
}

input:active,
input:target,
input:focus,
textarea:active,
textarea:target,
textarea:focus {
    outline: none;
}

#email {
    text-transform: lowercase;
}

label {
    pointer-events: none;
    user-select: none;
}

#aaa {
    display: none;
}

#aaa a {
    transition: all.2s;
}

#aaa a:hover {
    color: var(--main);
}

.me {
    margin: 0;
    padding: 0;
    margin-left: auto;
    margin-right: auto;
    width: fit-content;
    height: fit-content;
}

.me img {
    width: 250px;
    max-width: 70vw;
    border-radius: 100px / 25px;
    height: auto;
    filter: drop-shadow(0 0 7em var(--main));
    transition: all.4s;
}

.me img:hover {
    filter: drop-shadow(0 0 3em var(--main));
}

form {
    position: relative;
    display: flex;
    flex-direction: column;
    gap: 4rem;
    width: 100%;
    max-width: 600px;
    margin-left: auto;
    margin-right: auto;
    margin-top: 50px;
    padding: 20px;
    background-color: var(--black);
    border-radius: 10px;
}

form label {
    font-size: 1rem;
    color: var(--light) !important;
    margin-bottom: 5px;
    position: absolute;
    padding:5px;
    transform: translateY(-100%);
    left: 22px;
}

form input[type="text"],
form input[type="email"],
form textarea {
    position: relative;
    width: calc(100% - 20px);
    padding: 10px;
    font-size: 1rem;
    border: none;
    border-radius: 5px;
    background-color: rgba(140, 20, 252, 0.2); /* Adjusted to match #8c14fc */
    color: var(--main);
    transition: background-color 0.3s ease;
}

form input[type="text"]:focus,
form input[type="email"]:focus,
form textarea:focus {
    background-color: rgba(140, 20, 252, 0.4); /* Slightly darker tone of #8c14fc on focus */
    outline: none;
}

form textarea {
    resize: vertical;
    min-height: 100px;
}

form input[type="submit"] {
    width: 100%;
    padding: 10px 20px;
    font-size: 1rem;
    color: var(--light);
    background-color: var(--main);
    border: none;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s ease, transform 0.2s ease;
}

form input[type="submit"]:hover {
    background-color: var(--light);
    color: var(--black);
}

.modal {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
}

.modal-overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    z-index: 1;
}

.modal-content {
    position: relative;
    z-index: 2;
    background-color: var(--light);
    padding: 20px 30px;
    border-radius: 10px;
    box-shadow: 0 4px 15px rgba(0, 0, 0, 0.2);
    text-align: center;
    max-width: 400px;
    width: 90%;
}

.modal-content p {
    margin-bottom: 20px;
    font-size: 1.2rem;
    color: var(--black);
}

.modal-content button {
    padding: 10px 20px;
    font-size: 1rem;
    color: var(--light);
    background-color: var(--main);
    border: none;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s ease, transform 0.2s ease;
}

.modal-content button:hover {
    background-color: var(--light);
    color: var(--black);
    transform: scale(1.05);
}